import React, { useEffect, useState } from 'react'
import { Router } from '@reach/router'
import JoinA from '../components/joina'
import JoinB from '../components/join'
import NotFoundPage from './404'
import { graphql } from 'gatsby'

const JoinRoute = (props: { data: any }) => {
  const [joinPage, setJoinPage] = useState('1')
  useEffect(() => {
    const joinPageId = window.localStorage.getItem('joinPage')
    console.log({ joinPageId })
    setJoinPage(joinPageId)
  }, [joinPage])
  return (
    <Router basepath="/join">
      {joinPage == '1' ? (
        <JoinA path="/*" data={props.data} />
      ) : (
        <JoinB path="/*" data={props.data} />
      )}
      <NotFoundPage default withLayout={false} />
    </Router>
  )
}

export default JoinRoute

export const query = graphql`
  query {
    allImageSharp(
      filter: {
        fluid: {
          originalName: {
            in: [
              "shop-millions-of-products.png"
              "create-share-link.png"
              "your-shopping-board.png"
              "chirpyest-curated-style-finds.png"
              "inspiration-on-our-blog.png"
              "chirpyest-works-on-mobile.png"
            ]
          }
        }
      }
      sort: { order: ASC, fields: fluid___originalName }
    ) {
      edges {
        node {
          id
          fluid(maxHeight: 1024, quality: 100) {
            src
            srcSet
            base64
            aspectRatio
            originalImg
            originalName
            sizes
          }
        }
      }
    }
  }
`
